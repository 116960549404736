<script setup lang="ts">
const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    buttonCollapseText?: {
      open: string
      close: string
    }
    quote?: string
    trackingPage?: string
  }>(),
  {
    buttonCollapseText: () => ({
      open: 'house.read_less',
      close: 'house.read_more',
    }),
    quote: '',
    trackingPage: 'Product page',
  },
)

const descriptionIsOpen = ref(false)
const limitToTruncate = 170

const buttonText = computed(() => ({
  open: t(props.buttonCollapseText.open),
  close: t(props.buttonCollapseText.close),
}))

const isTruncate = computed(
  () => props.quote && props.quote.length > limitToTruncate,
)
const ellipsizeQuote = computed(() =>
  isTruncate.value
    ? props.quote.substring(0, limitToTruncate).concat('...')
    : props.quote,
)
</script>

<template>
  <div class="pb-9 mb-9 border-b border-gray-200">
    <div v-if="quote" class="flex items-start my-6 whitespace-pre-wrap">
      <span v-if="descriptionIsOpen">{{ quote }}</span>
      <span v-else>{{ ellipsizeQuote }}</span>
    </div>

    <base-collapse
      v-if="isTruncate"
      v-model="descriptionIsOpen"
      color="black"
      :button-text="buttonText"
      :button-attrs="{ class: '!items-center' }"
    />
  </div>
</template>

<style>
.house-description__icon {
  margin: 0 1rem 0 0;
  @apply text-primary-500;
}
</style>
