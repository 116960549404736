<script setup lang="ts">
import { getDestinationSlug } from '~/helpers/getDestinationSlug'
import type { HouseSlug, Locale } from 'lc-services/types'
import type { HouseDestination } from '~/types/house/types'

const props = withDefaults(
  defineProps<{
    houseDestinations?: HouseDestination
    witoutLinks?: boolean
    withCountry?: boolean
  }>(),
  {
    houseDestinations: () => ({}) as HouseDestination,
    witoutLinks: false,
    withCountry: false,
  },
)

const { locale } = useI18n()

const houseLocalisations = computed(() => {
  const destination = props.houseDestinations
  const cluster = props.houseDestinations?.cluster
  const country = props.houseDestinations?.country

  const destis = []

  if (destination?.name) {
    destis.push({
      name: destination.name,
      slug: destination.destinationUrl,
      searchUrl: destination.destinationUrl,
    })
  }

  if (cluster?.name) {
    destis.push({
      name: cluster.name,
      slug: cluster.slug,
      searchUrl: cluster.searchUrl,
    })
  }

  if (country?.name && props.withCountry) {
    destis.push({
      name: country.name,
      slug: country.slug,
      searchUrl: country.searchUrl,
    })
  }

  return destis
    .filter((x) => x.name !== undefined)
    .map((desti, index, destis) => {
      const name = index === destis.length - 1 ? desti.name : `${desti.name},`
      return {
        ...desti,
        name,
      }
    })
})

const getHref = (slug: HouseSlug) => {
  return getDestinationSlug(slug)[locale.value as Locale].path
}
</script>

<template>
  <ul class="pl-0 mb-2 flex items-center list-none">
    <li
      v-for="localisation in houseLocalisations"
      :key="localisation.slug[locale as Locale]"
      class="text-md"
    >
      <span v-if="witoutLinks" class="text-gray-600 mr-1">
        {{ localisation.name }}
      </span>
      <nuxt-link
        v-else
        :to="
          getHref(
            localisation.searchUrl ? localisation.searchUrl : localisation.slug,
          )
        "
        class="house-destination-breadcrumb__link mr-1"
      >
        {{ localisation.name }}
      </nuxt-link>
    </li>
  </ul>
</template>

<style>
.house-destination-breadcrumb__link,
.house-destination-breadcrumb__link:hover,
.house-destination-breadcrumb__link:focus {
  @apply text-gray-600;
}
</style>
